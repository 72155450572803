








import { Component, Vue } from "vue-property-decorator";
import FooterNav from "@/components/FooterNav.vue";
import VerticalSlider from "@/components/VerticalSlider.vue";
import CaseStudyListing from "@/components/CaseStudies/CaseStudyListings.vue";

@Component({
  name: "CaseStudies",
  components: { CaseStudyListing, VerticalSlider, FooterNav }
})
export default class CaseStudies extends Vue {}
